import React, { useState ,useEffect} from 'react'
import {View,Text,TouchableOpacity
    ,TextInput,Image,ScrollView,FlatList,StyleSheet
    } from 'react-native'
    import NavBar from '../navbar/NavBar';
import {useDispatch,useSelector} from "react-redux";
import colors from '../../assets/colors/colors';
import NavBar2 from '../navbar/NavBar2';
import NavBar3 from '../navbar/NavBar3';

import world from '../../assets/iconpng/gray/world.png'
import saveimg from '../../assets/iconpng/black/save.png'
import axios from 'axios';
import {styles} from '../../assets/styleSAP/formaInputSap'
import useTable from "../../assets/hooks/useTable";
import { api } from '../../assets/assets';





const TableDelete = ({navigation,pagetoEdit1,data1,tableData1,headerColumns1,headerColumnsOrginal1,
  hide,apilink}) => {
   

  
 const [loading,setloading]=useState(0)
  const pagetoEdit=pagetoEdit1
  const [data,setData]=useState(data1)
  const [search, setSearch] = useState("");
  const [tableData, setTableData] = useState(tableData1);
  const [headerColumns, setHeaderColumns] = useState(headerColumns1);
  const [sortOrder, setSortOrder] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [hiddenColumns, setHiddenColumns] = useState([]);

const [hideTable,setHideTable]=useState(0)

  const [headerColumnsOrginal, setheaderColumnsOrginal] = useState(headerColumnsOrginal1);


const refresh =()=>{
    axios({
        method: "get",
        url: api+apilink,
        headers: { 
            'Accept': 'application/json',
            'Authorization': 'Bearer '
        },
    })
    .then((response) => {
         console.log(response.data);
         setTableData(response.data);
         setData(response.data);
      setHeaderColumns(Object.keys(response.data[0] || {}));
      setheaderColumnsOrginal(Object.keys(response.data[0] || {}))
      setloading(0)

    })
    .catch((error) => {
        console.log(error);
    });
}

const Delete =(itemid)=>{
  setloading(1)
  if (window.confirm("هل انت متاكد من الحذف "+itemid) == true) {
    axios({
      method: "delete",
      url: api+apilink+"/"+itemid,
      headers: { 
          'Accept': 'application/json',
          'Authorization': 'Bearer '
      },
  })
  .then((response) => {
       console.log(response.data);
       setTimeout(()=>refresh(), 1000);

       
  })
  .catch((error) => {
      console.log(error);
  });
  } else {
    alert( "تم الغاء الحذف")
    setloading(0)

  }
}

  // Update the table data when the incoming data prop changes
  useEffect(() => {
    toggleColumns(hide)
  }, []);
  const toggleColumns = (headersToHide) => {
    const newHeaderColumns = headerColumns.filter((header) => {
      return !headersToHide.some((hiddenHeader) => hiddenHeader.header === header);
    });
  
    const newHiddenColumns = [...hiddenColumns, ...headersToHide.map((hiddenHeader) => hiddenHeader.header)];
  
    const newTableData = tableData.map((item) => {
      return headersToHide.reduce((prevItem, hiddenHeader) => {
        return {
          ...prevItem,
          [hiddenHeader.header]: null,
        };
      }, item);
    });
  
    setHeaderColumns(newHeaderColumns);
    setHiddenColumns(newHiddenColumns);
    setTableData(newTableData);
  };

  const toggleColumn = (header) => {
    if (hiddenColumns.includes(header)) {
      // If the column is hidden, show it
      setHiddenColumns(hiddenColumns.filter((column) => column !== header));
      setHeaderColumns([...headerColumns, header]);
      setTableData(
        tableData.map((item) => {
          return { ...item, [header]: hiddenColumns.includes(header) ? item[header] : null };
        })
      );
    } else {
      // If the column is shown, hide it
      setHiddenColumns([...hiddenColumns, header]);
      setHeaderColumns(headerColumns.filter((column) => column !== header));
      setTableData(
        tableData.map((item) => {
          const { [header]: value, ...rest } = item;
          return rest;
        })
      );
    }
  };


 
  const[TableHandleSearch,handleHeaderClick,handlesortedData,handleDownload]=useTable(data);

  // Handle filtering the table when the search input changes
  const handleSearch = (text) => {setSearch(text); setTableData(TableHandleSearch(text,data));};



// Render each row of the table as a FlatList item
const renderItem = ({ item }) => {
  return (
   

  <View style={styles.row}
  
  >
     <TouchableOpacity 
  onPress={()=>Delete(item.id)}
  >
  <Text style={[styles.cell,{color:"red"}]}>
           حذف
           </Text>
          </TouchableOpacity>
    <TouchableOpacity 
  onPress={()=>navigation.navigate(pagetoEdit,
  {item:item})}
  >
  <Text style={[styles.cell,{color:"blue"}]}>
           تعديل
           </Text>
          </TouchableOpacity>
  {headerColumns.map((header,index) => (

  <Text key={header} style={styles.cell}>
  {item[header]}
  </Text>
  ))}
 
  </View>
 
  );
  };

  const renderHeader = () => {
    return (
      <View style={styles.tableHeaderView}>
         <Text  style={styles.cellHeader}>
           حذف
          </Text>
           <Text  style={styles.cellHeader}>
           تعديل
          </Text>
        {headerColumns.map((header, index) => (
        <TouchableOpacity 
        onPress={()=>{
            setSortOrder(handlesortedData(header,sortOrder))
            setTimeout(setTableData(handleHeaderClick(header,sortOrder)), 1000);
                
             
                 //toggleColumn(header)
                
               
        }}
        >

        <Text key={index} style={styles.cellHeader}>
            {header}
          </Text>
          </TouchableOpacity>

        ))}
      
      </View>
    );
  };
  
  // Calculate the total number of pages based on the number of rows and the max rows per page
  const totalPages = Math.ceil(tableData.length / 20);
  
  // Create an array of page numbers to render page buttons
  const pageNumbers = Array.from(Array(totalPages), (_, i) => i + 1);
  
  // Filter the table data based on the current page
  const startIndex = (currentPage - 1) * 20;
  const endIndex = startIndex + 20;
  const paginatedData = tableData.slice(startIndex, endIndex);
  
  return (
    hideTable==1? <TouchableOpacity style={styles.Button} onPress={()=>setHideTable(0)}>
    <Text>open table</Text>
    </TouchableOpacity>:
    <View style={[styles.container2,{minHeight:1080}]}>
   


   {/* <TouchableOpacity style={styles2.downloadButton} onPress={()=>setHideTable(1)}>
  <Text style={styles2.downloadButtonText}>hide</Text>
  </TouchableOpacity> */}
      
<Text >Table</Text>
      {loading==1?<Text>Loading .........</Text>:null}
  <View style={styles.container}>



    {/* Tools  */}
<View style={styles.formaView}>
<Text style={styles.formaViewHeader}>Tools</Text>
 {/* 4 row input */}
 <View style={{flexDirection:"row",alignItems:"center"}}>

{/* input search */}
<View style={styles.inputView}>
<Text style={styles.inputHeaderText}>Search</Text>
 <TextInput
         style={styles.TextInput}
         placeholder="Search"
         value={search}
         onChangeText={
          handleSearch
        }
       />
       </View>
{/* end of input search */}
 

{/* refresh btn */}
<TouchableOpacity style={styles.Button}  onPress={()=>refresh()}>
  <Text >refresh</Text>
  </TouchableOpacity>
  {/* end of refresh btn */}

{/* CSV btn */}
  <TouchableOpacity style={styles.Button} onPress={handleDownload}>
  <Text >CSV</Text>
  </TouchableOpacity>
    {/* end of CSV btn */}



</View>
{/* end of 4 rows */}


</View>
{/* end of Tools  */}

    {/* Table  */}
    <View style={styles.formaView}>
<Text style={styles.formaViewHeader}>Table</Text>
<View style={{padding:5,flex:1 ,marginVertical:20 , minHeight:500}}>
<ScrollView horizontal={true}        showsHorizontalScrollIndicator
     >


        <View style={{marginVertical:15}}>
          {renderHeader()}
          <FlatList
            data={tableData}
            renderItem={renderItem}
            keyExtractor={(item, index) => index.toString()}
          />
        </View>
      </ScrollView>

  <View style={styles.pageContainer}>
  {pageNumbers.map((pageNumber) => (
  <TouchableOpacity
  key={pageNumber}
  style={[
  styles.pageButton,
  currentPage === pageNumber && styles.currentPageButton,
  ]}
  onPress={() => setCurrentPage(pageNumber)}
  >
  <Text
  style={[
  styles.pageButtonText,
  currentPage === pageNumber && styles.currentPageButtonText,
  ]}
  >
  {pageNumber}
  </Text>
  </TouchableOpacity>
  ))}
  </View>
</View>

</View>

  
  </View>
  </View>
  );
  };
  
  

export default TableDelete;