import React, { useState ,useEffect} from 'react'
import {View,Text,SafeAreaView,TouchableOpacity
  ,TextInput,Image,StyleSheet,Modal,ScrollView
  ,FlatList
  } from 'react-native'

    import DateTimePicker from 'react-datetime-picker';

    import NavBar from '../../navbar/NavBar';
import {useDispatch,useSelector} from "react-redux";
import colors from '../../../assets/colors/colors';
import NavBar2 from '../../navbar/NavBar2';
import NavBar3 from '../../navbar/NavBar3';

import world from '../../../assets/iconpng/gray/world.png'
import saveimg from '../../../assets/iconpng/black/save.png'
import {styles} from '../../../assets/styleSAP/formaInputSap'
import {datePickerStyles} from '../../../assets/stylesDark/datePickerStyles'
import {searchPicker} from '../../../assets/stylesDark/searchPicker'
import { api, imageAPI } from '../../../assets/assets';
import axios from 'axios';
import useaxios from '../../../assets/hooks/useaxios';





const AddItemsImg =({navigation})=>{

  const Imagecard = ({ item }) => {
    return(
      <TouchableOpacity
      key={item.id}
      onPress={() => handleImgValueChange(item.imgurl)}
      style={searchPicker.pickerItem}
    >
<Image
source={{uri: item.imgurl}}
style={{width: 250, height: 250}}
/>                  
<Text>{item.id}</Text>             

    </TouchableOpacity>
    )
  }
    const nav=useSelector(state =>state.navbar.nav2Active);
    const [error,seterror]=useState('')
    const[getaxios]=useaxios()


    const [imgSearch, setImgSearch] = useState('');
    const [imgs,setimgs]=useState(imageAPI)

    const [selectedimgurl, setSelectedimgUrl] = useState('');

    const [isImgPickerVisible, setImgPickerVisible] = useState(false);
    const [filteredimgs, setFilteredimgs] = useState(imgs);


    const handleSearchimg= (query) => {
      const filtered = imgs.filter((img) =>
        img.imgurl.toLowerCase().includes(query.toLowerCase())
      );
      setFilteredimgs(filtered);
      setImgSearch(query);
    };

    const handleImgValueChange = (value) => {
      setSelectedimgUrl(value);
      setImgPickerVisible(false);
    };

     // meals 
     const [meals,setmeals] = useState([])
     const [mealsSearchQuery, setMealsSearchQuery] = useState('');
     const [selectedMealId, setSelectedMealId] = useState('');
     const [selectedMealName, setSelectedMealName] = useState('');

     const [isMealPickerVisible, setMealPickerVisible] = useState(false);
     const [filteredMeal, setFilteredMeal] = React.useState(meals);

     const handleSearchmeal= (query) => {
       const filtered = meals.filter((meal) =>
         meal.itemNameAR.toLowerCase().includes(query.toLowerCase())
       );
       setFilteredMeal(filtered);
       setMealsSearchQuery(query);
     };
   
     const handleMealValueChange = (value,name) => {
       setSelectedMealId(value);
       setMealPickerVisible(false);
       setSelectedMealName(name)
     };
     // end of meals

    useEffect(() => {
      
      getaxios('item').then(data => {
        setmeals(data)
        setFilteredMeal(data)
        console.log(data)
       }
        ).catch(error => console.error(error));
    }, [])

    const add =()=>{
      axios({
        method: "post",
        url: api+"ItemImg",
        headers: { 
            'Accept': 'application/json',
            'Authorization': 'Bearer '
        },
        data:{url:selectedimgurl,item_id:selectedMealId}
    })
    .then((response) => {
         console.log(response.data);
         alert("done")
         seterror("done")
    })
    .catch((error) => {
        console.log(error);
    });
    }
    
     
    return(
      <View style={[styles.container,{minHeight:1080}]}>
      <NavBar navigation={navigation}/>
      <NavBar2 navigation={navigation} nav2active={1}/>
      <NavBar3 navigation={navigation} nav2active={1}/>



        
      <View style={styles.container2}>
        <Text >Add Category page</Text>


  {/* meal */}
  <View style={styles.inputView}>
<Text style={styles.inputHeaderText}>meal </Text>
<TouchableOpacity onPress={() => setImgPickerVisible(true)}
                    style={styles.TextInput}

            >
            {selectedimgurl&&
             <Image
             source={{uri: selectedimgurl}}
             style={{width: 500, height: 500}}
           /> 
            } 
        
    
        
      </TouchableOpacity>
</View>
 {/*end of meal */}




{/* start of modal */}
<Modal visible={isImgPickerVisible} animationType="slide">
        <View style={searchPicker.modalContainer}>
        <TouchableOpacity onPress={() => setImgPickerVisible(false)}
        style={searchPicker.closeButton}
        >
              <Text style={searchPicker.closeButtonText}>Close</Text>
            </TouchableOpacity>


          <View style={styles.searchContainer}>
            <TextInput
              style={searchPicker.searchInput}
              onChangeText={handleSearchimg}
              value={imgSearch}
              placeholder="Search img"
            />
          </View>


          <ScrollView>

          <View style={styles.pickerContainer}>
            <View style={styles.picker}>
            <FlatList

data={filteredimgs}
keyExtractor={item => item.id}
numColumns={5}
renderItem={({ item ,index}) => (
  <Imagecard item={item}  />
)}

/>
            </View>
          </View>
          </ScrollView>

    

        </View>
      </Modal>
{/* end of modal */}


  {/* meal */}
  <View style={styles.inputView}>
<Text style={styles.inputHeaderText}>meal </Text>
<TouchableOpacity onPress={() => setMealPickerVisible(true)}
                    style={styles.TextInput}

            >
        
          <Text
          style={{textAlign:"left"}}
          >{ selectedMealName  +" "+ selectedMealId || 'Select a meal'}</Text>
        
      </TouchableOpacity>
</View>
 {/*end of meal */}
 {/* select meals */}

         
 <Modal visible={isMealPickerVisible} animationType="slide">
        <View style={searchPicker.modalContainer}>
        <TouchableOpacity onPress={() => setMealPickerVisible(false)}
        style={searchPicker.closeButton}
        >
              <Text style={searchPicker.closeButtonText}>Close</Text>
            </TouchableOpacity>


          <View style={styles.searchContainer}>
            <TextInput
              style={searchPicker.searchInput}
              onChangeText={handleSearchmeal}
              value={mealsSearchQuery}
              placeholder="Search meal"
            />
          </View>


          <ScrollView>

          <View style={styles.pickerContainer}>
            <View style={styles.picker}>
              {filteredMeal.map((meal, index) => (
                <TouchableOpacity
                  key={index}
                  onPress={() => handleMealValueChange(meal.id,meal.itemNameAR)}
                  style={searchPicker.pickerItem}
                >
                  <Text style={{color:"black",textAlign:"left"}}>{meal.itemNameAR} {meal.id}</Text>
                  

                </TouchableOpacity>
              ))}
            </View>
          </View>
          </ScrollView>

    

        </View>
      </Modal>
      {/* end of meal select */}


            <View >
            

          
            </View>

          {/* all text input */}
 








      










          







        
        </View>
        <View style={styles.saveView}>
            {error && <Text style={{ color: 'red' }}>{error}</Text>}

                <TouchableOpacity style={styles.Button}       
    onPress={()=>add()}
>
                {/* <Image 
                source={saveimg}  
                style={{width: 19, height: 19 ,margin:5}}
            /> */}
                    <Text style={styles.saveBtnText}>add</Text>
                </TouchableOpacity>

            </View>

           
</View>
    )
}

export default AddItemsImg;