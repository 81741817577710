import React, { useState ,useEffect} from 'react'
import {SafeAreaView,TouchableOpacity,View,Text
    ,TextInput,Image,StyleSheet,Modal,ScrollView,Picker
    } from 'react-native'
    import NavBar from '../../navbar/NavBar';
import {useDispatch,useSelector} from "react-redux";
import colors from '../../../assets/colors/colors';
import NavBar2 from '../../navbar/NavBar2';
import NavBar3 from '../../navbar/NavBar3';

import world from '../../../assets/iconpng/gray/world.png'
import saveimg from '../../../assets/iconpng/black/save.png'
import {styles} from '../../../assets/styleSAP/formaInputSap'
import {datePickerStyles} from '../../../assets/stylesDark/datePickerStyles'
import {searchPicker} from '../../../assets/stylesDark/searchPicker'
import axios from 'axios';
import DateTimePicker from 'react-datetime-picker';
import useaxios from '../../../assets/hooks/useaxios';
import { api } from '../../../assets/assets';
import InprogressBtn from '../mealsTable/InprogressBtn';

import { PDFDownloadLink, Page, Document } from '@react-pdf/renderer';
import P from './P';
import pdficon from '../../../assets/iconpng/newicons/pdf.png'
import bill from '../../../assets/iconpng/newicons/bill.png'

const PrintMeals =({navigation})=>{
  const dispatch = useDispatch();

    const nav=useSelector(state =>state.navbar.nav2Active);

    const [error,seterror]=useState()

    // select date 
    const [selectedDate, setSelectedDate] = useState(new Date());
    const [showDatePicker, setShowDatePicker] = useState(false);
    const [datestring,setdatestring]= useState()
  

    const [year, setYear] = useState(2023);
    const [month, setMonth] = useState(1);
    const [day, setDay] = useState(1);
  
    const years = Array.from({length: 20}, (v, i) => 2023+i);
    const months = Array.from({length: 12}, (v, i) => i + 1);
    const days = Array.from({length: 31}, (v, i) => i + 1);


  const remaining=useSelector(state =>state.remainingReducer.remaining);
    const handleDateChange = (date) => {
      setSelectedDate(date);
      setShowDatePicker(false);
      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, "0");
      const day = String(date.getDate()).padStart(2, "0");
      setdatestring(`${year}-${month}-${day}`)

      axios({
        method: 'post',
        url: api+'searchChefMealsByDate',
        headers: { 
          'Accept': 'application/json',
          'Authorization': 'Bearer '
        },
        data:{
         
          date:`${year}-${month}-${day}`
        
        }
      })
      .then(response => {
   
       console.log(response.data)
      })
      .catch(error => {
        seterror(error.message);

      });

     

    };
  
    //const dateString = selectedDate ? selectedDate.toISOString().slice(0, 10) : '';
    // end select date 





    //categorychef
      const [categories,setcategories] = useState([])
      const [searchQuery, setSearchQuery] = React.useState('');
      const [selectedCategory, setSelectedCategory] = React.useState('');
      const [selectedcategoryName, setselectedcategoryName] = React.useState('');

      
      const [isPickerVisible, setPickerVisible] = React.useState(false);
      const [filteredCategories, setFilteredCategories] = React.useState(categories);
    
      const handleSearch = (query) => {
        const filtered = categories.filter((category) =>
          category.toLowerCase().includes(query.toLowerCase())
        );
        setFilteredCategories(filtered);
        setSearchQuery(query);
      };
    
      const handleValueChange = (value,name) => {
        setSelectedCategory(value);
        setPickerVisible(false);
        setselectedcategoryName(name)
      };
      // end of categorychef


      // meals 
      const [meals,setmeals] = useState([])
      const [mealsSearchQuery, setMealsSearchQuery] = useState('');
      const [selectedMealId, setSelectedMealId] = useState('');
      const [selectedMealName, setSelectedMealName] = useState('');

      const [isMealPickerVisible, setMealPickerVisible] = useState(false);
      const [filteredMeal, setFilteredMeal] = React.useState(meals);

      const handleSearchmeal= (query) => {
        const filtered = meals.filter((meal) =>
          meal.itemNameAR.toLowerCase().includes(query.toLowerCase())
        );
        setFilteredMeal(filtered);
        setMealsSearchQuery(query);
      };
    
      const handleMealValueChange = (value,name) => {
        setSelectedMealId(value);
        setMealPickerVisible(false);
        setSelectedMealName(name)
      };
      // end of meals



      // array of chef meals
      const [items, setItems] = React.useState([]);
      const addItemToArray = (array, selectedDate
        ,selectedMealId,selectedMealName
        ) => {


        const newItem = { 
          date:selectedDate,
          item_id:selectedMealId,
          itemNameAR:selectedMealName
        };
        const newArray = [...array, newItem];
        return newArray;
      };
      
      // Function to delete an item from the array
      const deleteItemFromArray = (array, index) => {
        const newArray = [...array];
        newArray.splice(index, 1);
        return newArray;
      };

      
      const handleAddItem = (selectedDate,selectedMealId,selectedMealName) => {
        if (!selectedDate  || !selectedMealId || !selectedMealName) {
          // Handle the case where one or more variables are empty or undefined
          seterror("One or more variables are empty or undefined");
         
        }else{
          const newItems = addItemToArray(items, selectedDate,selectedMealId,selectedMealName);
          setItems(newItems);
          seterror('')
        }
       
      };
    
      const handleDeleteItem = (index) => {
        const newItems = deleteItemFromArray(items, index);
        setItems(newItems);
      };
      // end array of chef meals



      const saveChefMeals = ()=>{
        const formattedDate = `${year}-${month < 10 ? '0' + month : month}-${day < 10 ? '0' + day : day}`;

        axios({
          method: 'post',
          url: api+'getSubscriptionLive',
          headers: { 
            'Accept': 'application/json',
            'Authorization': 'Bearer '
          },
          data:{
             data:items,
            meals:items,
            snacks:items,
            date:formattedDate
          
          }
        })
        .then(response => {
          console.log("yes")
          console.log(response.data);
          setItems(response.data)
          dispatch({
            type : "setRemaining",
            payload:response.data.length
        })

         alert("done")
        })
        .catch(error => {
          console.log(error);

        });
      }

      const[getaxios]=useaxios()
      useEffect(() => {

        setTimeout(()=>window.open("http://caloricluberp.c1.biz","_self"), 3600000)


        






       getaxios('ChefMealsCategory').then(data => {
        setcategories(data)
        setFilteredCategories(data)
        console.log(data)
       }
        ).catch(error => console.error(error));

        getaxios('item').then(data => {
          setmeals(data)
          setFilteredMeal(data)
          console.log(data)
         }
          ).catch(error => console.error(error));

      }, [])

      const MyPDF = (items) => {
        return (
          <Document>
            {items.map((item, index) => (
              //<Page size={{ width: 200, height: 200 }}>
                       <Page size="A4">

                <div>
                  <h1>{item.name}</h1>
                  <h1>{item.phone}</h1>
                  <h1>{item.id}</h1>


                </div>
              </Page>
            ))}
          </Document>
        );
      };
     
    return(
      <View style={[styles.container,{minHeight:1080}]}>
      <NavBar navigation={navigation}/>
      <NavBar2 navigation={navigation} nav2active={5}/>
      <NavBar3 navigation={navigation} nav2active={1}/>
   
      <View style={styles.container2}>
        <Text >print meals with subscribe details page</Text>

         {/*Date  */}
<View style={styles.formaView}>
<Text style={styles.formaViewHeader}>Date</Text>

   {/* Select the date*/}
 <View style={styles.inputView}>
<Text style={styles.inputHeaderText}>Select the date</Text>
<Picker
        selectedValue={year}
        onValueChange={(itemValue) => setYear(itemValue)}
        style={styles.TextInput}

      >
        {years.map((y) => <Picker.Item key={y} label={`${y}`} value={`${y}`} />)}
      </Picker>
      <Picker
        selectedValue={month}
        onValueChange={(itemValue) => setMonth(itemValue)}
        style={styles.TextInput}

      >
        {months.map((m) => <Picker.Item key={m} label={`${m}`} value={`${m}`} />)}
      </Picker>
      <Picker
        selectedValue={day}
        onValueChange={(itemValue) => setDay(itemValue)}
        style={styles.TextInput}

      >
        {days.map((d) => <Picker.Item key={d} label={`${d}`} value={`${d}`} />)}
      </Picker>
</View>
 {/*end of Select the date*/}

</View>
{/* end of Date */}






      {/* select meals */}

         
      <Modal visible={isMealPickerVisible} animationType="slide">
        <View style={searchPicker.modalContainer}>
        <TouchableOpacity onPress={() => setMealPickerVisible(false)}
        style={searchPicker.closeButton}
        >
              <Text style={searchPicker.closeButtonText}>Close</Text>
            </TouchableOpacity>


          <View style={styles.searchContainer}>
            <TextInput
              style={searchPicker.searchInput}
              onChangeText={handleSearchmeal}
              value={mealsSearchQuery}
              placeholder="Search meal"
            />
          </View>


          <ScrollView>

          <View style={styles.pickerContainer}>
            <View style={styles.picker}>
              {filteredMeal.map((meal, index) => (
                <TouchableOpacity
                  key={index}
                  onPress={() => handleMealValueChange(meal.id,meal.itemNameAR)}
                  style={searchPicker.pickerItem}
                >
                  <Text style={{color:"black",textAlign:"left"}}>{meal.itemNameAR} </Text>
                  

                </TouchableOpacity>
              ))}
            </View>
          </View>
          </ScrollView>

    

        </View>
      </Modal>
      {/* end of category select */}











{/* add btn */}



  {/* end of add btn */}


  {/* {
    items&&items.map((item,index) => (
        <TouchableOpacity
        onPress={()=>handleDeleteItem(index)}
        key={index}
        >
            <Text>
              {item.date} + {item.category_chef_id} + {item.categoryNameAR}
            
            {item.item_id} {item.itemNameAR}
            </Text>
        </TouchableOpacity>
    ))
} */}




        </View>

            {/* add btn */}

<View style={[styles.saveView,{flexDirection:"row"}]}>
            {error && <Text style={{ color: 'red' }}>{error}</Text>}

                <TouchableOpacity style={styles.Button}        onPress={()=>saveChefMeals()}
>
                {/* <Image 
                source={saveimg}  
                style={{width: 19, height: 19 ,margin:5}}
            /> */}
                    <Text style={styles.saveBtnText}> جلب الجدول</Text>
                </TouchableOpacity>
                <View style={styles.Button} >
                <Image 
    source={pdficon}  
    style={{width: 24, height: 24 }}
/>
{
            items&&
           <P items={items} dateofmeals={day+"-"+month+"-"+year}/>

          }
</View>

<View style={styles.Button}>
<Image 
    source={bill}  
    style={{width: 24, height: 24 }}
/>
<Text>{remaining}</Text></View>
            </View>

        


<View style={styles.container2}>

{
            items&&
           items.map((item,index)=>
<View style={styles.formaView2}>
<Text style={styles.formaViewHeader}>id : {item.id} <InprogressBtn/>
 </Text>      

  {/* 4 row input */}
  <View style={{flexDirection:"row",margin:10}}>
 
 {/* item subscribe id  */}
 <View style={styles.inputView}>
<Text style={styles.inputHeaderText}>subscribe id : </Text>
<TextInput
  value={item.id}
  style={styles.TextInput}
/>
</View>
{/* end of item subscribe id  */}

 
 {/* item Name */}
 <View style={styles.inputView}>
<Text style={styles.inputHeaderText}>Name :</Text>
<TextInput
  value={item.name}
  style={styles.TextInput}
/>
</View>
{/* end of item Name */}

 {/* item phone */}
 <View style={styles.inputView}>
<Text style={styles.inputHeaderText}>phone :</Text>
<TextInput
  value={item.phone}
  style={styles.TextInput}
/>
</View>
{/* end of item phone */}

</View>  
{/* end of 4 row input */}

  {/* 4 row input */}
  <View style={{flexDirection:"row",margin:10}}>
 
 {/* item subscribe id  */}
 <View style={styles.inputView}>
<Text style={styles.inputHeaderText}>start date : </Text>
<TextInput
  value={item.startDate}
  style={styles.TextInput}
/>
</View>
{/* end of item subscribe id  */}

 {/* item subscribe id  */}
 <View style={styles.inputView}>
<Text style={styles.inputHeaderText}>end date : </Text>
<TextInput
  value={item.endDate}
  style={styles.TextInput}
/>
</View>
{/* end of item subscribe id  */}
</View>

<View style={{flexDirection:"row",margin:10}}>
 <View style={styles.inputView}>
<Text style={styles.inputHeaderText}>carb</Text>
<TextInput
style={styles.TextInput}
value={item.carbs}
/>
</View>
<View style={styles.inputView}>
<Text style={styles.inputHeaderText}>protien</Text>
<TextInput
style={styles.TextInput}
value={item.protein}
/>
</View>
<View style={styles.inputView}>
<Text style={styles.inputHeaderText}>meals</Text>
<TextInput
style={styles.TextInput}
value={item.meals}
/>
</View>
<View style={styles.inputView}>
<Text style={styles.inputHeaderText}>snacks</Text>
<TextInput
style={styles.TextInput}
value={item.snacks}
/>
</View>

</View>



                  {item.mealsChef.map((meal, index) => (
                                    <View>
                  <Text style={styles.cell} >meal: {meal.itemNameEN}</Text>

                                    </View>

                  )
                  )}
                      {item.snacksCehf.map((snack, index) => (
                                    <View>
                  <Text style={styles.cell} >snack : {snack.itemNameEN}</Text>

                                    </View>

                  )
                  )}



{/* address  */}

 {/* 4 row input */}
 <View style={{flexDirection:"row",justifyContent:"space-between",alignItems:"center"}}>


{/* input Area */}
<View style={styles.inputView}>
<Text style={styles.inputHeaderText}>Area</Text>
<TextInput
style={styles.TextInput}
value={item.area_nameAR}
/>

</View>
{/* end of input Area */}


{/* input block */}
<View style={styles.inputView}>
<Text style={styles.inputHeaderText}>block</Text>
<TextInput
style={styles.TextInput}
value={item.block}
/>
</View>
{/* end of input block */}

{/* input Street */}
<View style={styles.inputView}>
<Text style={styles.inputHeaderText}>Street</Text>
<TextInput
style={styles.TextInput}
value={item.street}
/>
</View>
{/* end of input Street */}

{/* input Jadah */}
<View style={styles.inputView}>
<Text style={styles.inputHeaderText}>Jadah</Text>
<TextInput
style={styles.TextInput}
value={item.jadah}
/>
</View>
{/* end of input Jadah */}

{/* input Home */}
<View style={styles.inputView}>
<Text style={styles.inputHeaderText}>Home</Text>
<TextInput
style={styles.TextInput}
value={item.home}
/>
</View>
{/* end of input Home */}


 </View>
 {/* end of 4 rows */}
 {/* 4 row input */}
 <View style={styles.ViewInput2Row}>

{/* input Flat */}
<View style={styles.inputView}>
<Text style={styles.inputHeaderText}>Flat</Text>
<TextInput
style={styles.TextInput}
value={item.flat}
/>
</View>
{/* end of input Floor */}



{/* input Floor */}
<View style={styles.inputView}>
<Text style={styles.inputHeaderText}>Floor</Text>
<TextInput
style={styles.TextInput}
value={item.floor}
/>


{/* input zone */}
<View style={styles.inputView}>
<Text style={styles.inputHeaderText}>zone</Text>
<TextInput
style={styles.TextInput}
value={item.zoneId}
/>



</View>


{/* input delevery */}
<View style={styles.inputView}>
<Text style={styles.inputHeaderText}>deleviryTime</Text>
<TextInput
style={styles.TextInput}
value={item.deleviryTimeId}
/>



</View>



</View>
{/* end of input Floor */}


 {/* end of address  */}


 </View>  


 
 
  </View>
           
           )
          
          }


</View>
  {/* end of add btn */}
      

           
</View>
    )
}

export default PrintMeals;
