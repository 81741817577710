import React, { useState ,useEffect} from 'react'
import {View,Text,SafeAreaView,TouchableOpacity
    ,TextInput,Image,  Picker,Button

    } from 'react-native'

    import DateTimePicker from 'react-datetime-picker';

    import NavBar from '../../navbar/NavBar';
import {useDispatch,useSelector} from "react-redux";
import colors from '../../../assets/colors/colors';
import NavBar2 from '../../navbar/NavBar2';
import NavBar3 from '../../navbar/NavBar3';

import world from '../../../assets/iconpng/gray/world.png'
import saveimg from '../../../assets/iconpng/black/save.png'
import {styles} from '../../../assets/styleSAP/formaInputSap'
import {datePickerStyles} from '../../../assets/stylesDark/datePickerStyles'

import axios from 'axios';
import { api } from '../../../assets/assets';

const areas = [
  { area_name: 'kuwait', area_id: 1 },
  { area_name: 'salmi', area_id: 2 },
];



const EditPrice =({navigation,route})=>{
    const nav=useSelector(state =>state.navbar.nav2Active);
    const { item} = route.params;


    const [error, setError] = useState(null);
    const [packageId, setPackageId] = useState(item.package_id);
    const [packageDays, setPackageDays] = useState(item.package_days);
    const [packageDaysInWeek, setPackageDaysInWeek] = useState(item.package_days_in_week);
    const [price, setPrice] = useState(item.price);
    const [discount, setDiscount] = useState(item.discount);
    const[noteAr,setnoteAr]=useState(item.noteAr);
    const[noteEn,setnoteEn]=useState(item.noteEn);
    const[packageShowDays,setpackageShowDays]=useState(item.packageShowDays);
    const[snacks,setSnacks]=useState(item.snacks);
    const[meals,setMeals]=useState(item.meals);
  
    const [Package,setPackages] = useState([
      { id: 0, packageNameAR: 'جاري التحميل', packageNameEN: 'loading' },
    ]);
  
    const handleSubmit = () => {
      if (!packageId || !packageDays || !packageDaysInWeek || !price) {
        // Show an error message if any of the variables are empty.
        setError('Please fill in all the fields.');
      } else {
        // Submit the form data in one array.
        setError("done");
  
        const formData = {
          package_id: packageId,
          package_days: packageDays,
          package_days_in_week: packageDaysInWeek,
          price: parseFloat(price),
          discount: parseFloat(discount) || 0,
          noteAr: noteAr,
          noteEn: noteEn,
          packageShowDays: packageShowDays,
          snacks: snacks,
          meals: meals,
        };
        axios({
          method: "put",
          url: api+"PackagePrice/"+item.id,
          headers: { 
              'Accept': 'application/json',
              'Authorization': 'Bearer '
          },
          data:formData
      })
      .then((response) => {
           console.log(response.data);
           alert("done")
      })
      .catch((error) => {
      //   console.log(error.response.data.data['phone']=="The phone has already been taken."?setError("The phone has already been taken."):setError("error server"));
      console.log(error)
      });
  
        console.log('Form data:', formData);
      }
    };

    useEffect(() => {
      axios({
        method: "get",
        url: api+"Package",
        headers: { 
            'Accept': 'application/json',
            'Authorization': 'Bearer '
        },
    })
    .then((response) => {
         console.log(response.data);
        setPackages(response.data)
    })
    .catch((error) => {
        console.log(error);
    });
    }, [])
    
     
    return(
      <View style={[styles.container,{minHeight:1080}]}>
      <NavBar navigation={navigation}/>
      <NavBar2 navigation={navigation} nav2active={3}/>
      <NavBar3 navigation={navigation} nav2active={3}/>



        
      <View style={styles.container2}>
        <Text >Add Price for Package page</Text>


          {/* all text input */}
 

  {/* Select the package  */}
<View style={styles.formaView}>
<Text style={styles.formaViewHeader}>the package</Text>

{/* Select a package*/}
<View style={styles.inputView}>
<Text style={styles.inputHeaderText}>Select a package:</Text>
<Picker
        selectedValue={packageId}
        onValueChange={(value) => setPackageId(value)}
        style={styles.TextInput}
      >
        {Package.map((item) => (
          <Picker.Item
            key={item.id}
            label={item.packageNameAR}
            value={item.id}
          />
        ))}
      </Picker>
</View>
 {/*end of Select a package:*/}



</View>
  {/* end of  Select the package  */}


    {/* days */}
<View style={styles.formaView}>
<Text style={styles.formaViewHeader}>days</Text>


 {/* 2 row input */}
 <View style={styles.ViewInput2Row}>

 {/* days in month*/}
 <View style={styles.inputView}>
<Text style={styles.inputHeaderText}>days in month</Text>
<Picker
        selectedValue={packageDays}
        onValueChange={(value) => setPackageDays(value)}
        style={styles.TextInput}

      >
        {Array.from({ length: 30 }, (_, i) => i + 1).map((item) => (
          <Picker.Item key={item} label={item.toString()} value={item} />
        ))}
      </Picker>
      </View>
 {/*end of days in month*/}


  {/* days in week*/}
  <View style={styles.inputView}>
<Text style={styles.inputHeaderText}>Select the number of days in a week:</Text>
<Picker
        selectedValue={packageDaysInWeek}
        onValueChange={(value) => setPackageDaysInWeek(value)}
        style={styles.TextInput}
      >
        {Array.from({ length: 7 }, (_, i) => i + 1).map((item) => (
          <Picker.Item key={item} label={item.toString()} value={item} />
        ))}
      </Picker>
      </View>
 {/*end of days in week*/}

 </View>
 {/* end of 2 row input */}


</View>
  {/* end of days  */}



 {/* price */}
 <View style={styles.formaView}>
<Text style={styles.formaViewHeader}>price</Text>


 {/* 2 row input */}
 <View style={styles.ViewInput2Row}>

{/* Select the price:*/}
<View style={styles.inputView}>
<Text style={styles.inputHeaderText}>Select the price:</Text>
<TextInput
    value={price}
    onChangeText={setPrice}
    style={styles.TextInput}

  />
{/* <Picker
        selectedValue={price}
        onValueChange={(value) => setPrice(value)}
        style={styles.TextInput}
      >
        {Array.from({ length: 200 }, (_, i) => i + 1).map((item) => (
          <Picker.Item key={item} label={item.toString()} value={item} />
        ))}
      </Picker> */}
      </View>
 {/*end of Select the price:*/}


 {/* Select the discount:*/}
<View style={styles.inputView}>
<Text style={styles.inputHeaderText}>Select the discount:</Text>
<TextInput
    value={discount}
    onChangeText={setDiscount}
    style={styles.TextInput}

  />
{/* <Picker
        selectedValue={discount}
        onValueChange={(value) => setDiscount(value)}
        style={styles.TextInput}
      >
        {Array.from({ length: 201 }, (_, i) => i ).map((item) => (
          <Picker.Item key={item} label={item.toString()} value={item} />
        ))}
       
      </Picker> */}
      </View>
 {/*end of Select the discount:*/}

 </View>
 {/* end of 2 row input */}


</View>
  {/* end of price  */}

  <View style={styles.formaView}>
<Text style={styles.formaViewHeader}>snacks and meals</Text>
<View style={styles.ViewInput2Row}>

<View style={styles.inputView}>
<Text style={styles.inputHeaderText}>Select the meals:</Text>
<TextInput
    value={meals}
    onChangeText={setMeals}
    style={styles.TextInput}

  />
  </View>

  <View style={styles.inputView}>
<Text style={styles.inputHeaderText}>Select the snacks:</Text>
<TextInput
    value={snacks}
    onChangeText={setSnacks}
    style={styles.TextInput}

  />
  </View>

</View>
</View>


<View style={styles.formaView}>
<Text style={styles.formaViewHeader}>notes</Text>
<View style={styles.ViewInput2Row}>

<View style={styles.inputView}>
<Text style={styles.inputHeaderText}>note ar</Text>
<TextInput
    value={noteAr}
    onChangeText={setnoteAr}
    style={styles.TextInput}

  />
  </View>

  <View style={styles.inputView}>
<Text style={styles.inputHeaderText}>note En</Text>
<TextInput
    value={noteEn}
    onChangeText={setnoteEn}
    style={styles.TextInput}

  />
  </View>

</View>
</View>

<View style={styles.formaView}>
<Text style={styles.formaViewHeader}>show days</Text>
<View style={styles.ViewInput2Row}>

<View style={styles.inputView}>
<Text style={styles.inputHeaderText}>show days</Text>
<TextInput
    value={packageShowDays}
    onChangeText={setpackageShowDays}
    style={styles.TextInput}

  />
  </View>



</View>
</View>




        </View>
        {/* end of input format */}


        <View style={styles.saveView}>
            {error && <Text style={{ color: 'red' }}>{error}</Text>}

                <TouchableOpacity style={styles.Button}       
   onPress={handleSubmit}
>
                {/* <Image 
                source={saveimg}  
                style={{width: 19, height: 19 ,margin:5}}
            /> */}
                    <Text style={styles.saveBtnText}>add</Text>
                </TouchableOpacity>

            </View>
        

           
</View>
    )
}

export default EditPrice;