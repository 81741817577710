import React from 'react'
import {TouchableOpacity} from 'react-native'
import { PDFDownloadLink, Page, Text, View, Document,Font,StyleSheet } from '@react-pdf/renderer';
import {styles} from '../../../assets/styleSAP/formaInputSap'
import '../../../../index.css'

const P =({items,dateofmeals})=>{
     const Almarai_Regular = require('../../../assets/fonts/Almarai/Almarai-Regular.ttf');
     Font.register({ family: 'Almarai-Regular', src: Almarai_Regular });

    function getDateAsString(dateofmeals) {
      const today = new Date(dateofmeals);
      const year = today.getFullYear();
      const month = String(today.getMonth() + 1).padStart(2, '0');
      const day = String(today.getDate()).padStart(2, '0');
      const dateString = `${year}-${month}-${day}`;
      return dateString;
    }
    const stylesTitle = StyleSheet.create({
        title: {
          fontFamily:"Almarai-Regular",
          fontSize:13
        },

    formaView:{
      borderWidth:0.5,
      marginBottom:10
   
  },
      })
    //   const MyPDF = () => {
    //     return (
    //       <Document>
    //         <Page size="A4">
    //           <View>
    //             <Text style={styles.title}>Hello, World!</Text>
    //           </View>
    //         </Page>
    //       </Document>
    //     );
    //   };

      const MyPDF = () => {
        return (
            <Document>

            {items.map((item, index) => (
              //<Page size={{ width: 200, height: 200 }}
             <Page size={'a4'} style={{padding:20}}>

<View style={stylesTitle.formaView}>
  <View style={{borderWidth:0.5}}>
  <Text style={{alignSelf:"center",fontSize:40,fontWeight:"bold"}}>Box-{item.id} 
 </Text> 
  </View>
     
    
  
{/* end of item phone */}

  {/* 4 row input */}
  <View style={{flexDirection:"row"}}>
 
  {/* item phone */}
  <View style={{borderWidth:0.5,padding:3}}>
<Text style={[styles.inputHeaderText,stylesTitle.title]}>phone :{item.phone}</Text>
</View>
{/* end of item phone */}


 
 {/* item Name */}
 <View style={{borderWidth:0.5,padding:3}}>

<Text style={{fontFamily:"Almarai-Regular"}}>Name : {item.name}</Text>

</View>
{/* end of item Name */}



</View>  


<View style={{flexDirection:"row"}}>
<View style={{borderWidth:0.5,padding:3}}>
  <Text style={[styles.inputHeaderText,stylesTitle.title]}>date : {dateofmeals}</Text>
</View>
<View style={{borderWidth:0.5,padding:3}}>
  <Text style={[styles.inputHeaderText,stylesTitle.title]}>S date : "  {item.startDate} "</Text>
</View>
{/* input carb */}
<View style={{borderWidth:0.5,padding:3}}>
  <Text style={[styles.inputHeaderText,stylesTitle.title]}>E date : " {item.endDate} "</Text>
</View>
</View>



{/* end of 4 row input */}
<View style={{flexDirection:"row"}}>
  {/* input carb */}
<View style={{borderWidth:0.5,padding:3}}>
  <Text style={[styles.inputHeaderText,stylesTitle.title]}>carb : {item.carbs}</Text>
</View>
{/* input carb */}
<View style={{borderWidth:0.5,padding:3}}>
  <Text style={[styles.inputHeaderText,stylesTitle.title]}>protein : {item.protein}</Text>
</View>
{/* input meals */}
<View style={{borderWidth:0.5,padding:3}}>
  <Text style={[styles.inputHeaderText,stylesTitle.title]}>meals : {item.meals}</Text>
</View>
{/* input snacks */}
<View style={{borderWidth:0.5,padding:3}}>
  <Text style={[styles.inputHeaderText,stylesTitle.title]}>snacks : {item.snacks}</Text>
</View>
</View>
<View style={{borderWidth:0.5,padding:3}}>
  <Text style={[styles.inputHeaderText,stylesTitle.title]}>notes : {item.notes}</Text>
</View>



  {item.mealsChef.map((meal, index) => (
 <View style={{borderWidth:0.5,padding:3}}>
 <Text style={[styles.inputHeaderText,stylesTitle.title]} >meal : {meal.itemNameEN}</Text>

                                    </View>

                  )
                  )}

{item.snacksCehf.map((snack, index) => (
 <View style={{borderWidth:0.5,padding:3}}>
 <Text style={[styles.inputHeaderText,stylesTitle.title]} >snack : {snack.itemNameEN}</Text>

                                    </View>

                  )
                  )}



                 
                  
{/* address  */}



 {/* 4 row input */}
 <View style={{flexDirection:"row"}}>

{/* input Area */}
<View style={{borderWidth:0.5,padding:3}}>
<Text style={[styles.inputHeaderText,stylesTitle.title]}>Area : {item.area_nameEN}</Text>
</View>
{/* end of input Area */}



{/* input block */}
<View style={{borderWidth:0.5,padding:3}}>
<Text style={[styles.inputHeaderText,stylesTitle.title]}>block : {item.block}</Text>
</View>
{/* end of input block */}

{/* input Street */}
<View style={{borderWidth:0.5,padding:3}}>
<Text style={[styles.inputHeaderText,stylesTitle.title]}>Street : {item.street}</Text>  
</View>
{/* end of input Street */}

{/* input Jadah */}
<View style={{borderWidth:0.5,padding:3}}>
<Text style={[styles.inputHeaderText,stylesTitle.title]}>Jadah : {item.jadah}</Text>
</View>
{/* end of input Jadah */}




 </View>
 {/* end of 4 rows */}
 {/* 4 row input */}
 <View style={{flexDirection:"row"}}>
  {/* input Home */}
<View style={{borderWidth:0.5,padding:3}}>
<Text style={[styles.inputHeaderText,stylesTitle.title]}>Home : {item.home}</Text>
</View>
{/* end of input Home */}

{/* input Flat */}
<View style={{borderWidth:0.5,padding:3}}>
<Text style={[styles.inputHeaderText,stylesTitle.title]}>Flat : {item.flat}</Text>
</View>
{/* end of input Floor */}



{/* input Floor */}
<View style={{borderWidth:0.5,padding:3}}>
  <Text style={[styles.inputHeaderText,stylesTitle.title]}>Floor : {item.floor}</Text>
</View>





</View>
{/* end of input Floor */}

<View style={{flexDirection:"row"}}>
{/* input zone */}
<View style={{borderWidth:0.5,padding:3}}>
  <Text style={[styles.inputHeaderText,stylesTitle.title]}
  
  >zone : {item.zoneId}</Text>
</View>

<View style={{borderWidth:0.5,padding:3}}>
  <Text style={[styles.inputHeaderText,stylesTitle.title]}
  
  >deleviryTime : {item.deleviryTimeId}</Text>
</View>
</View>


 {/* end of address  */}


 </View>   

 <View style={stylesTitle.formaView}>
  <View style={{borderWidth:0.5}}>
  <Text style={{alignSelf:"center",fontSize:45,fontWeight:"bold"}}>Box-{item.id} 
 </Text> 
  </View>
     
    
   

  {/* 4 row input */}
  <View style={{flexDirection:"row"}}>
 
  {/* item phone */}
  <View style={{borderWidth:0.5,padding:3}}>
<Text style={[styles.inputHeaderText,stylesTitle.title]}>phone :{item.phone}</Text>
</View>
{/* end of item phone */}


 
 {/* item Name */}
 <View style={{borderWidth:0.5,padding:3}}>

<Text style={[styles.inputHeaderText,stylesTitle.title]}>Name : {item.name}</Text>

</View>
{/* end of item Name */}
</View>  
{/* end of 4 row input */}



<View style={{flexDirection:"row"}}>
<View style={{borderWidth:0.5,padding:3}}>
  <Text style={[styles.inputHeaderText,stylesTitle.title]}>date : {dateofmeals}</Text>
</View>
<View style={{borderWidth:0.5,padding:3}}>
  <Text style={[styles.inputHeaderText,stylesTitle.title]}>S date : "  {item.startDate} "</Text>
</View>
{/* input carb */}
<View style={{borderWidth:0.5,padding:3}}>
  <Text style={[styles.inputHeaderText,stylesTitle.title]}>E date : " {item.endDate} "</Text>
</View>
</View>






<View style={{flexDirection:"row"}}>
  {/* input carb */}
<View style={{borderWidth:0.5,padding:3}}>
  <Text style={[styles.inputHeaderText,stylesTitle.title]}>carb : {item.carbs}</Text>
</View>
{/* input carb */}
<View style={{borderWidth:0.5,padding:3}}>
  <Text style={[styles.inputHeaderText,stylesTitle.title]}>protein : {item.protein}</Text>
</View>
{/* input meals */}
<View style={{borderWidth:0.5,padding:3}}>
  <Text style={[styles.inputHeaderText,stylesTitle.title]}>meals : {item.meals}</Text>
</View>
{/* input snacks */}
<View style={{borderWidth:0.5,padding:3}}>
  <Text style={[styles.inputHeaderText,stylesTitle.title]}>snacks : {item.snacks}</Text>
</View>
</View>
<View style={{borderWidth:0.5,padding:3}}>
  <Text style={[styles.inputHeaderText,stylesTitle.title]}>notes : {item.notes}</Text>
</View>



  {item.mealsChef.map((meal, index) => (
 <View style={{borderWidth:0.5,padding:3}}>
 <Text style={[styles.inputHeaderText,stylesTitle.title]} >meal : {meal.itemNameEN}</Text>

                                    </View>

                  )
                  )}

{item.snacksCehf.map((snack, index) => (
 <View style={{borderWidth:0.5,padding:3}}>
 <Text style={[styles.inputHeaderText,stylesTitle.title]} >snack : {snack.itemNameEN}</Text>

                                    </View>

                  )
                  )}



                 
                  
{/* address  */}



 {/* 4 row input */}
 <View style={{flexDirection:"row"}}>

{/* input Area */}
<View style={{borderWidth:0.5,padding:3}}>
<Text style={[styles.inputHeaderText,stylesTitle.title]}>Area : {item.area_nameEN}</Text>
</View>
{/* end of input Area */}



{/* input block */}
<View style={{borderWidth:0.5,padding:3}}>
<Text style={[styles.inputHeaderText,stylesTitle.title]}>block : {item.block}</Text>
</View>
{/* end of input block */}

{/* input Street */}
<View style={{borderWidth:0.5,padding:3}}>
<Text style={[styles.inputHeaderText,stylesTitle.title]}>Street : {item.street}</Text>  
</View>
{/* end of input Street */}

{/* input Jadah */}
<View style={{borderWidth:0.5,padding:3}}>
<Text style={[styles.inputHeaderText,stylesTitle.title]}>Jadah : {item.jadah}</Text>
</View>
{/* end of input Jadah */}




 </View>
 {/* end of 4 rows */}
 {/* 4 row input */}
 <View style={{flexDirection:"row"}}>
  {/* input Home */}
<View style={{borderWidth:0.5,padding:3}}>
<Text style={[styles.inputHeaderText,stylesTitle.title]}>Home : {item.home}</Text>
</View>
{/* end of input Home */}

{/* input Flat */}
<View style={{borderWidth:0.5,padding:3}}>
<Text style={[styles.inputHeaderText,stylesTitle.title]}>Flat : {item.flat}</Text>
</View>
{/* end of input Floor */}



{/* input Floor */}
<View style={{borderWidth:0.5,padding:3}}>
  <Text style={[styles.inputHeaderText,stylesTitle.title]}>Floor : {item.floor}</Text>
</View>




{/* input carb */}
<View style={{borderWidth:0.5,padding:3}}>
  <Text style={[styles.inputHeaderText,stylesTitle.title]}>carb : {item.carbs}</Text>
</View>


</View>
{/* end of input Floor */}

<View style={{flexDirection:"row"}}>
{/* input zone */}
<View style={{borderWidth:0.5,padding:3}}>
  <Text style={[styles.inputHeaderText,stylesTitle.title]}>zone : {item.zoneId}</Text>
</View>

<View style={{borderWidth:0.5,padding:3}}>
  <Text style={[styles.inputHeaderText,stylesTitle.title]}
  
  >deleviryTime : {item.deleviryTimeId}</Text>
</View>
</View>


 {/* end of address  */}


 </View>  
              </Page> 
            ))}
          </Document>
        );
      };
    return(
        <View>
           
            <PDFDownloadLink document={<MyPDF />} fileName="subscribesData.pdf"
            
        >
        {({ blob, url, loading, error }) =>
          loading ? 'Loading document...' : 'Print PDF'
        }
      </PDFDownloadLink>

        </View>
    )
}

export default P;